/* Contact page info */

import { Flex, Link, Text } from "@chakra-ui/react"
import React from "react"
import { HoursIcon, LocationIcon, PhoneIcon } from "../icons"

const Info = () => {
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      grow={1}
      justify="space-evenly"
      my={[4, 8]}
    >
      <Flex align="center" my={[2, 2, 0]}>
        <LocationIcon />
        <Text fontSize={{ base: "sm", sm: "md" }}>Vienna, VA 22182</Text>
      </Flex>
      <Flex
        as={Link}
        href="tel:+1-703-895-5538"
        align="center"
        my={[2, 2, 0]}
        _hover={{ textDecor: "none" }}
      >
        <PhoneIcon />
        <Text fontSize={{ base: "sm", sm: "md" }}>703-895-5538</Text>
      </Flex>
      <Flex align="center" my={[2, 2, 0]}>
        <HoursIcon />
        <Text fontSize={{ base: "sm", sm: "md" }}>
          Monday - Friday: 8 AM - 6 PM
          <br />
          Saturday - Sunday: CLOSED
        </Text>
      </Flex>
    </Flex>
  )
}

export default Info
