/* Contact page component */

import { Center, Container, Flex, Heading, Text } from "@chakra-ui/react"
import React from "react"
import Form from "./form"
import Info from "./info"
import Seo from "../seo"

const Contact = () => {
  return (
    <>
      <Seo title="Contact" />
      <Flex
        direction="column"
        justify="center"
        align="center"
        py={[4, 8]}
        bg="gray.700"
      >
        <Heading as="h1" size="xl" color="teal.300" mb={2} textAlign="center">
          Contact
        </Heading>
        <Text color="gray.50" textAlign="center">
          How can we help you?
        </Text>
      </Flex>
      <Container maxW="container.xl" py={[4, 8]}>
        <Info />
        <Center>
          <Flex
            bg="gray.300"
            justify="center"
            p={[2, 4]}
            my={[2, 4]}
            maxW={500}
            w="100%"
          >
            <Text textAlign="center" fontSize="sm">
              We respect your privacy.
              <br />
              We will only use your personal information to contact you.
            </Text>
          </Flex>
        </Center>
        <Center>
          <Form />
        </Center>
      </Container>
    </>
  )
}

export default Contact
