/* Contact page form */

import { Box, Button, Flex, useToast } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import React from "react"
import { useForm } from "react-hook-form"
import { FaPaperPlane } from "react-icons/fa"
import * as yup from "yup"
import { PhoneMaskInput, TextareaInput, TextInput } from "../custom-inputs"

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

const schema = yup.object({
  name: yup.string().trim().required("Please enter your name"),
  email: yup
    .string()
    .trim()
    .email(
      "Please enter a valid email address in the following format: email@example.com"
    )
    .required("Please enter your email"),
  phone: yup
    .string()
    .required("Please enter your phone number")
    .matches(phoneRegex, "Please enter a valid 10-digit US phone number"),
  message: yup.string().trim().required("Please enter your message"),
})

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Form = () => {
  const toast = useToast()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
  })

  const onSubmit = (data, e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...data,
      }),
    })
      .then(() => {
        toast({
          title: "Thank you for contacting us!",
          description:
            "We have received your mesage and will get back in touch with you soon!",
          status: "success",
          duration: 7000,
          isClosable: true,
          position: "top",
          variant: "subtle",
        })
      })
      .catch(error => {
        console.log(error)
      })

    e.target.reset()
    reset({ phone: "" })
  }

  return (
    <Flex
      direction="column"
      as="form"
      w="100%"
      maxW={500}
      onSubmit={handleSubmit(onSubmit)}
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      my={[4, 8]}
    >
      <input type="hidden" name="form-name" value="contact" />
      <TextInput
        name="name"
        type="text"
        isInvalid={errors.name}
        label="Name"
        control={control}
        errorMessage={errors.name && errors.name.message}
      />
      <TextInput
        name="email"
        type="email"
        isInvalid={errors.email}
        label="Email address"
        control={control}
        errorMessage={errors.email && errors.email.message}
      />
      <PhoneMaskInput
        isInvalid={errors.phone}
        control={control}
        errorMessage={errors.phone && errors.phone.message}
      />
      <TextareaInput
        name="message"
        type="text"
        isInvalid={errors.message}
        label="Your message"
        control={control}
        errorMessage={errors.message && errors.message.message}
      />
      <Box textAlign={{ base: "center", sm: "right" }} my={[4, 8]}>
        <Button
          colorScheme="yellow"
          isLoading={isSubmitting}
          type="submit"
          leftIcon={<FaPaperPlane />}
        >
          Send Message
        </Button>
      </Box>
    </Flex>
  )
}

export default Form
